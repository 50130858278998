
import firebase from 'firebase'
const config = {
    apiKey: "AIzaSyD0m5ZHiFikmKK9V_-fCH96Cq94y2q6R1o",
    authDomain: "thanks-54769.firebaseapp.com",
    databaseURL: "https://thanks-54769.firebaseio.com",
    projectId: "thanks-54769",
    storageBucket: "thanks-54769.appspot.com",
    messagingSenderId: "441840974208",
    appId: "1:441840974208:web:a458c3436aefd3e88de965"
};
firebase.initializeApp(config);
export default firebase;