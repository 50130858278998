export default [
  {
    css:
      'url("https://thximages.s3.eu-west-2.amazonaws.com/PHOTO-2020-08-25-11-58-44+(1).jpg")',
    height: 300
  },
  {
    css:
      'url("https://thximages.s3.eu-west-2.amazonaws.com/kev.jpg")',
    height: 300
  },
  {
    css:
      'url("https://thximages.s3.eu-west-2.amazonaws.com/PHOTO-2020-08-25-11-58-44+(2).jpg")',
    height: 300
  },
  {
    css:
      'url("https://thximages.s3.eu-west-2.amazonaws.com/PHOTO-2020-08-25-11-58-44.jpg")',
    height: 300
  },
  {
    css:
      'url(" https://thximages.s3.eu-west-2.amazonaws.com/IMG_6631.JPG")',
    height: 300
  },
  {
    css:
      'url(" https://thximages.s3.eu-west-2.amazonaws.com/cleanroom.jpg")',
    height: 300
  },
  {
    css:
      'url(" https://thximages.s3.eu-west-2.amazonaws.com/c5bd5085-1832-412d-9212-0163f270ad15.JPG")',
    height: 300
  },
  {
    css:
      'url(" https://thximages.s3.eu-west-2.amazonaws.com/PHOTO-2020-08-25-12-21-21.jpg")',
    height: 300
  },
  {
    css:
      'url(" https://thximages.s3.eu-west-2.amazonaws.com/20130412_130554.jpg")',
    height: 300
  },
  {
    css:
      'url(" https://thximages.s3.eu-west-2.amazonaws.com/20180406_105938.jpg")',
    height: 300
  },
  {
    css:
      'url(" https://thximages.s3.eu-west-2.amazonaws.com/20180808_203526.jpg")',
    height: 300
  },
  {
    css:
      'url(" https://thximages.s3.eu-west-2.amazonaws.com/20190705_183729.jpg")',
    height: 300
  },
  {
    css:
      'url("https://thximages.s3.eu-west-2.amazonaws.com/20190705_192115.jpg")',
    height: 300
  },
  {
    css:
      'url(" https://thximages.s3.eu-west-2.amazonaws.com/20190705_210146.jpg")',
    height: 300
  }
]
