import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { SectionLeft, SectionRight, SectionMiddle } from "../Sections";

class Gallery extends React.Component {
  state = {
    pageNumber: 0,
    sections: [],
    hasMoreItems: true,
    types: [],
  };

  getContent = (page) => {
    const num = 2 + (Math.floor(Math.random() * 10)) % 2;
    const newState = {
      sections: [
        ...this.state.sections,
        this.props.messages.slice(this.state.pageNumber, this.state.pageNumber + num),
      ],
      hasMoreItems: this.state.pageNumber + num < this.props.messages.length,
      pageNumber: this.state.pageNumber + num,
    };
    this.setState(newState);
  };

  render() {
    
    const { messages, images } = this.props;
    const { pageNumber, sections, hasMoreItems } = this.state;
    const items = [];
    sections.forEach((message, i) => {
      const type = i % 2
      const imgUrl = images[i % images.length].css
        .replace(`url("`, ``)
        .replace(`")`, ``);
      const config = { imgUrl, messages: message };
      let content;
      if (type < 1) {
        content = <SectionLeft imgUrl={imgUrl} messages={message} />;
      } else {
        content = <SectionRight {...config} />;
      } 
      items.push(
        //Section Component
        <div key={i}>
          {content}
        </div>
      );
      
    });
    return (
      messages.length > 1 && (
        <div style={{ width: '100%' }}>
          <InfiniteScroll
            pageStart={0}
            loadMore={this.getContent}
            hasMore={hasMoreItems}
            useWindow={false}
            loader={
              <Loader
                key="loader"
                type="Circles"
                color="#666"
                height={100}
                width={100}
              />
            }
          >
            {items}
          </InfiniteScroll>
        </div>
      )
    );
  }
}

export default Gallery;
