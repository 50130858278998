import React, { Component } from "react";
import firebase from "../firebase";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

class Sign extends Component {
  state = {
    currentItem: "",
    message: "",
    items: [],
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const itemsRef = firebase.database().ref("items");
    const item = {
      title: this.state.currentItem,
      user: this.state.message,
    };
    itemsRef.push(item);
    this.setState({
      currentItem: "",
      message: "",
    });
    document.location.reload(true)
  };
  componentDidMount() {

    const itemsRef = firebase.database().ref("items");
    itemsRef.on("value", (snapshot) => {
      let items = snapshot.val();
      let newState = [];
      for (let item in items) {
        newState.push({
          id: item,
          title: items[item].title,
          user: items[item].user,
        });
      }
      this.setState({
        items: newState,
      });
    });
  }
  removeItem(itemId) {
    const itemRef = firebase.database().ref(`/items/${itemId}`);
    itemRef.remove();
  }
  render() {
    const { classes } = this.props;
 
    return (
      <>
      <Box maxWidth="sm" pt={8} >
        <div className="app">
          <header>
            <div className="wrapper">
              <Container align="center">
            <h1 className="title quote">Please sign here</h1>
            </Container>
            </div>
          </header>
          <div className="container">
            <section className="add-item">
              <form onSubmit={this.handleSubmit}>
                <Box align="center"  pt={2} >
                  <TextField
                  className="main-input"
                  fullWidth
                    label="name"
                    name="message"
                    onChange={this.handleChange}
                    value={this.state.message}
                  />
                </Box>
                <Box align="center"  pt={2} >
                  <TextField
                  className="main-input"
                  fullWidth
                    label="message"
                    name="currentItem"
                    onChange={this.handleChange}
                    value={this.state.currentItem}
                    multiline
                    rows={4}
                  />
                </Box>

                <Box align="center"  pt={2} >
                  <Button
                    align="center"
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={this.handleSubmit}
                  >
                    Sign here
                  </Button>
                </Box>
              </form>
            </section>          
          </div>
        </div>
    
      </Box>
          
          </>
    );
  }
}
export default Sign

