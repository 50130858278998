import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Tilt from "./Tilt";

const tiltOptions = {
  max: 100,
  perspective: 100000,
  scale: 1.01,
};

const Message = ({ message }) => {
  return (
    <div className="quote message">
      <p>{message.title}</p>
      <p>{message.user}</p>
    </div>
  );
};

const SectionMain = ({ children }) => {
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.3,
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const controls = useAnimation();
  const variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  };
  if (inView) {
    controls.start("visible");
  }
  return (
    <motion.div
      initial="hidden"
      animate={controls}
      variants={variants}
      ref={ref}
      style={{
        display: "flex",
        width: "100%",
        flexDirection: `${isMobile ? "column" : "row"}`,
        position: "relative",
        justifyContent: "space-between",
        overflow: "visible",
        padding: "1rem 0",
        alignItems: "center",
      }}
    >
      {children}
    </motion.div>
  );
};

const SectionLeft = ({ imgUrl, messages }) => {
  return (
    <SectionMain>
      <div style={{ padding: "0 1rem" }} width="50%">
        {messages && messages.map((m) => <Message key={m._id} message={m} />)}
      </div>
      <div style={{ padding: "0 1rem" }} width="30%">
        <Tilt otions={tiltOptions}>
          <img className="image" src={imgUrl} style={{ width: "100%" }} />
        </Tilt>
      </div>
    </SectionMain>
  );
};

const SectionRight = ({ imgUrl, messages }) => {
  return (
    <SectionMain>
      <div style={{ padding: "0 1rem" }} width="30%">
        <Tilt otions={tiltOptions}>
          <img className="image" src={imgUrl} width="100%" />
        </Tilt>
      </div>
      <div style={{ padding: "0 1rem" }} width="50%">
        {messages && messages.map((m) => <Message key={m._id} message={m} />)}
      </div>
    </SectionMain>
  );
};

const SectionMiddle = ({ image, message }) => {
  return <div>NaoooMid</div>;
};

export { SectionLeft, SectionRight, SectionMiddle };
