import React from "react";
import kev from "../kev.jpg";
import firebase from "../firebase";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import An from "../An";
import data from '../data.js';
import Gallery from "../Components/Gallery";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
});

const shuffle = (arr) => {
  arr.sort(() => Math.random() - 0.5);
}


class Home extends React.Component {
  state = {
    items: [],
    images: data
  };
  componentDidMount() {
    const itemsRef = firebase.database().ref("items");
    itemsRef.on("value", (snapshot) => {
      let items = snapshot.val();
      let newState = [];
      let oldState = this.state;
      for (let item in items) {
        newState.push({
          id: item,
          title: items[item].title,
          user: items[item].user,
        });
      }
      shuffle(newState);
      this.setState({
        ...oldState,
        items: newState
      });
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Gallery messages={this.state.items} images={this.state.images} />
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

export default withStyles(styles)(Home);
